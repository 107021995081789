import React from 'react';

import Styled from 'styled-components';

import Toggle from '../../primespot-ui/components/toggle';
import Popover from '../../primespot-ui/components/popover';

const PopoverTest = () => {
  return (
    <Wrapper>
      <Toggle>
        {({ toggle, toggled, setToggled }) => (
          <div className="position">
            <Popover
              isOpen={toggled}
              placement="bottom"
              align="end"
              contentClass="popover-content"
              showArrow={true}
              arrowClass="popover-arrow"
              onOutsideClick={() => {
                setToggled(false);
              }}
              trigger={
                <span onClick={toggle}>
                  Click me to show the popover. I also toggle.
                </span>
              }
              content={
                <div>
                  <p>Here is the popover content.</p>
                </div>
              }
            />
          </div>
        )}
      </Toggle>
    </Wrapper>
  );
};

const Wrapper = Styled.div`
  position: relative;
  min-height: 100vh;
  min-width: 100vw;
  box-sizing: border-box;

  .position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .popover-content {
    background: white;
    padding: 12px;
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, .15);
  }

  .popover-arrow {
    color: rgb(245, 245, 245);
  }
`;

export default PopoverTest;
